<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addMatchm">添加渠道</el-button>
      </div>
    </GlobalForm>
    <!--弹框-->
    <el-dialog
      title="添加渠道"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <el-form ref="form" :rules="modelrules" :model="modelform" label-width="80px">
        <el-form-item label="渠道名称" prop="matchChannelName">
          <el-input v-model="modelform.matchChannelName" clearable maxlength="20" placeholder="请填写渠道名称，最多20个字"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactName">
          <el-input v-model="modelform.contactName" clearable maxlength="10" placeholder="请填写联系人姓名，最多10个字"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input v-model.number="modelform.contactPhone" maxlength="11" clearable onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请填写联系人手机号码，最多11位"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
let checkPhone = (rule, value, callback) => {
  let reg = /^1[3456789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}
export default {
  name: 'Form',
  data() {
    return {
      initData: null,
      dialogVisible: false,
      formItemList: [
        {
          key: 'matchChannelName',
          type: 'input',
          labelName: '渠道名称',
          placeholder: '请输入渠道名称'
        },
      ],
      modelform: {
        matchChannelName: "",
        contactName: "",
        contactPhone: "",
      },
      SeachParams: {},
      formRules: {
      },
      modelrules: {
        matchChannelName: [
          { required: true, message: '请填写渠道名称', trigger: 'blur' },
            { min: 1, max: 20, message: '长度限20字内', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' },
          { min: 1, max: 10, message: '长度限10字内', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请填写联系人手机号码', trigger: 'blur' },
          { type: 'number',validator: checkPhone, trigger: 'blur'},
        ]
      }
    }
  },
  created(){
    this.handleConfirm()
  },
  methods: {
    handleConfirm(data,cd) {
      console.log(data)
      const SeachParams = {
        matchChannelName:data?data.matchChannelName : '',
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.SeachParams = SeachParams
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    addMatchm() {
      this.dialogVisible = true
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false
    },
    // 确认添加
    handleSuccess() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            _api.saveMatchChannel(this.modelform).then((res) => {
              this.$message.success("添加成功");
              this.dialogVisible = false;
              this.handleConfirm(this.SeachParams)
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
  /deep/ .el-form--inline .el-form-item{
    margin-right: 30px!important;
  }
}
</style>
