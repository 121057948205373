<template>
  <div class="class-list">
    <GlobalInfoBar
      title="渠道管理"
      content="管理撮合交易渠道"
    >
      <div slot="right" style="text-align: right;flex:1;font-size:14px;">
        <span>渠道管理后台地址：<span @click="copy()" style="text-decoration: underline;cursor: pointer;">https://agent.btstars.cn</span>（点击复制）</span>
      </div>
    </GlobalInfoBar>
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From />
      </div>
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {};
  },
  methods: {
    copy() {
      const oInput = document.createElement("input");
      oInput.value = 'https://agent.btstars.cn';
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style>
</style>