<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="撮合交易" slot="matchNum" align="center">
        <template slot-scope="{ row }">
          <div
            @click="matchNumDialog(row)"
            class="tableBtn"
            v-if="row.matchNum"
          >
            <span style="border-bottom: 1px solid #0981ff">{{
              row.matchNum
            }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="渠道logo" slot="matchLogo" align="center">
        <template slot-scope="{ row }">
          <span class="tableBtn" @click="addLogo('see',row)" v-if="row.matchLogo">查看</span>
          <span class="tableBtn" @click="addLogo('add',row)" v-else>添加</span>
        </template>
      </el-table-column>
      <el-table-column label="启用渠道管理后台" slot="pc" align="center">
        <template slot-scope="{ row }">
          <el-switch inactive-color="#bebebe" v-model="row.isOpenPc" @change="handleSwitch(row,'switch')"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="operation"
        align="center"
      >
        <template slot-scope="{ row }">
            <!-- 启用 -->
          <el-button
            v-if="!row.isEnable"
            size="mini"
            type="success"
            round
            @click="disableopen(row)"
            >启用
          </el-button>
          <!-- 禁用 -->
          <el-button v-else size="mini" type="danger" round @click="disableopen(row)"
            >禁用
          </el-button>
          <el-button v-if="row.staffNum" size="mini" type="primary" round @click="handleSwitch(row,'see')"
            >查看账号
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="数据查看"
        slot="Channelation"
        align="center"
      >
        <template slot-scope="{ row }">
         <span @click="jumptoDitch(row)" style="color: #0981ff;cursor: pointer;text-decoration: underline;"> 渠道后台首页</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 动态口令弹出框 -->
    <el-dialog
      :title='commandTitle'
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div class="enable-tip" v-if="commandTitle == '禁用提示'">
        <div>说明：禁用后该渠道下撮合商家成交订单暂不扣款，可重新启用。</div>
        <div class="tip-sure">是否确认禁用该渠道？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：启用后该渠道撮合商家将重新进行撮合交易扣款</div>
        <div class="tip-sure">是否确认启用该渠道？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <DialogTbale
      @onClose="onClose"
      :loading="dialogLoading"
      :show="matchNumShow"
      title="查看撮合交易"
      :columns="matchNumColumns"
      :data="matchNumDataList"
      :currentPage="matchNumPage.pageNum"
      :total="matchNumPage.total"
      :pageSize="matchNumPage.pageSize"
      :width="700"
      @handleCurrentChange="matchNumHandleCurrentChange"
    >
      <!-- 00：未生效，01：已生效，02：已关闭 -->
      <el-table-column label="状态" slot="state" align="center">
        <template slot-scope="{ row }">
        <span>{{row.state=='00'?'未生效':row.state=='01'?'已生效':'已关闭'}}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 启用禁用提示框 -->
    <el-dialog
      :title="pcTitle"
      :visible.sync="disableShow"
      :close-on-click-modal="false"
      width="380px"
      @closed="dialogClosed"
    >
      <div v-if="!isFirst">
        <div style="color:#FF687B;font-size:12px;margin-bottom:10px;">{{!isOpenPc?'提示: 关闭后该账号不可登录渠道后台':'提示: 启用后该账号可恢复登录渠道后台'}}</div>
        <div style="color:#333333;font-size:16px;margin-bottom:30px;">{{!isOpenPc?'是否禁用该渠道管理后台？':'是否启用该渠道管理后台？'}}</div>
      </div>
      <div v-else>
        <div style="color:#FF687B;font-size:12px;margin-bottom:10px;">{{typeStr=='see'?'重置密码后，密码默认重置为mm123456':'提示：启用后系统自动获取联系电话为默认账号，登录账号不可修改'}}</div>
        <div class="pc-form">
          <div style="width: max-content;">账号：</div>
          <el-input
            disabled
            v-model="mobile"
            size="small"
            placeholder="请输入账号"
          ></el-input>
        </div>
        <div class="pc-form">
          <div style="width: max-content;">密码：</div>
          <el-input
           :disabled="typeStr=='see'"
           :type="isReset?'':'password'"
            v-model="userPwd"
            placeholder="请输入登录密码"
            size="small"
          ></el-input>
        </div>
        <div class="flex-je" v-if="typeStr=='see'"><el-button type="text" @click="reset">重置密码</el-button></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogCancel">取消</el-button>
        <el-button type="primary" @click.native="openPcSure" v-if="typeStr=='switch' || isReset" :loading="openLoading">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title='logoTitle'
      :visible.sync="logoShow"
      :close-on-click-modal="false"
      width="500px"
    >
      <div style="color:#FF687B;font-size:12px;margin-bottom:10px;">提示: 上传后，该渠道的门店商家个人中心上方显示渠道logo</div>
      <div style="margin-right:10px;position: relative;">
        <ElImgUpload
          key="matchLogo"
          :exist-image="matchLogo"
          @handleChange="handleChange($event)"
          @handleDelete="handleDelete($event)"
        />
        <span style="color:#FF687B;font-size:12px;margin-bottom:10px;">建议上传尺寸为280*30px的图片</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="logoCancel">取消</el-button>
        <el-button type="primary" @click.native="sureLogo">确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUpload from "../../../components/global/components/elements/upload-element";
export default {
  name: "Table",
  components: { ElImgUpload },
  props: {
  },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      matchNumShow: false,
      seachDataList: [],
      matchNumDataList: [],
      commandShow: false,
      butLoading: false,
      commandTitle: "",
      command: "",
      channelId: "",
      openPcRowId: "",
      mobile: "",
      userPwd: "",
      pcTitle: "温馨提示",
      isFirst: false,
      isOpenPc: false,
      isEnable: false,
      disableShow: false,
      openLoading: false,
      isReset: false,
      typeStr: "see",
      page:{
        pageNum:1,
        pageSize:10,
        total: 0,
      },
      matchNumPage: {
        pageNum:1,
        pageSize:10,
        total: 0,
      },
      pageNum:1,
      tableColumns: [
        { label: "渠道名称", prop: "matchChannelName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { label: "创建时间", prop: "createTime" },
        { slotName: 'matchNum' },
        { slotName: 'matchLogo' },
        { slotName: 'pc' },
        { slotName: 'operation' },
        {slotName:"Channelation"}
      ],
      matchNumColumns:[
        { label: "撮合回收商家", prop: "merchantName" },
        { label: "门店商家", prop: "companyName" },
        { label: "建立撮合时间", prop: "createTime" },
        { slotName: 'state' },
      ],
      matchId: "",
      logoTitle: "",
      logoShow: false,
      matchLogo: "",
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
  },
  methods: {
    //跳转到渠道后台首页
    jumptoDitch(row){
      const { href } = this.$router.resolve({
          name: "渠道后台首页",
          query: {
            id: row.id,
            name: row.matchChannelName,
          },
        });
        window.open(href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        matchChannelName: this.SeachParams.matchChannelName || '',
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.getMatchChannels(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    matchNumHandleCurrentChange(val) {
      if (val) {
        this.matchNumPage.pageNum = val;
      }
      const baseRequest = {
        matchChannelId: this.rowId,
        pageNum: this.matchNumPage.pageNum,
        pageSize: this.matchNumPage.pageSize,
      };
      this.dialogLoading = true
      _api.matchChannelList(baseRequest).then(res => {
        if (res.code === 1) {
          this.matchNumDataList = res.data.records;
          this.matchNumPage.total = res.data.total;
          this.matchNumPage.pageNum = res.data.current;
        }
        this.dialogLoading = false
      });
    },
    disableopen(row){
      if(row.isEnable){
        this.commandTitle = '禁用提示'
      }else{
        this.commandTitle = '启用提示'
      }
      this.commandShow = true
      this.channelId = row.id
      this.isEnable = !row.isEnable
    },
    matchNumDialog(row){
      this.matchNumShow = true
      this.rowId = row.id
      this.matchNumPage.pageNum = 1
      this.matchNumHandleCurrentChange()
    },
    onClose(){
      this.matchNumShow = false
    },
    // 启用/禁用弹框关闭
    commandClosed() {
      this.commandShow = false
      this.command = ""
    },
    // 启用/禁用弹框
    submit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.butLoading = true
      let params = {
        id:this.channelId,
        isEnable: this.isEnable,
        command:this.command
      }
      _api.updateMatchChannelIsEnable(params).then((res) => {
        this.$message.success(res.msg || '操作成功')
        this.butLoading = false
        this.commandShow = false
        this.command = ""
        this.handleCurrentChange()
      }).catch(() => {
        this.butLoading = false        
      });
    },
    // 开启关闭管理后台
    handleSwitch(row,type) {
      this.typeStr = type
      this.isReset = false
      if(type == 'see'){
        this.userPwd = "******"
        this.mobile = row.contactPhone
        this.isFirst = true
        this.pcTitle = '查看账号'
      }else{
        this.pcTitle = '温馨提示'
        if(row.staffNum){
          this.isFirst = false
        }else{
          this.mobile = row.contactPhone
          this.isFirst = true
          this.userPwd = ""
        }
      }
      this.openPcRowId = row.id
      this.isOpenPc = row.isOpenPc
      this.disableShow = true
    },
    dialogClosed() {
      this.disableShow = false
      this.handleCurrentChange()
    },
    openPcSure() {
      this.openLoading = true
      let params = {}
      if(this.typeStr == 'see'){
        params = {
          id: this.openPcRowId
        }
        _api.resetStaffPwd(params).then(res => {
          this.$message.success(res.msg || '操作成功')
          this.openLoading = false
          this.disableShow = false
        }).catch(() => {
          this.openLoading = false        
        });
        return false
      }
      if(this.isFirst){
        params = {
          mcId: this.openPcRowId,
          mobile: this.mobile,
          userPwd: this.userPwd,
        }
        _api.savePcStaff(params).then(res => {
          this.$message.success(res.msg || '操作成功')
          this.openLoading = false
          this.disableShow = false
        }).catch(() => {
          this.openLoading = false        
        });
      }else{
        params = {
          id:this.openPcRowId,
          isEnable: this.isOpenPc,
        }
        _api.updateMatchChannelOpenBg(params).then((res) => {
          this.$message.success(res.msg || '操作成功')
          this.openLoading = false
          this.disableShow = false
        }).catch(() => {
          this.openLoading = false
        });
      }
    },
    dialogCancel() {
      this.disableShow = false
    },
    // 重置密码
    reset() {
      this.isReset = true
      this.userPwd = 'mm123456'
    },
    // 添加查看LOGO
    addLogo(type,row) {
      this.matchId = row.id
      if(type == 'add'){
        this.logoTitle = "添加渠道logo"
        this.matchLogo = ""
      }else{
        this.logoTitle = "查看渠道logo"
        this.matchLogo = row.matchLogo
      }
      this.logoShow = true

    },
    // 弹框关闭
    logoCancel() {
      this.logoShow = false
    },
    // 弹框确认
    sureLogo() {
      let params = {
        matchId: this.matchId,
        matchLogo: this.matchLogo,
      }
      _api.saveMatchLogo(params).then(res => {
        if(res.code === 1){
          this.$message.success('操作成功')
          this.handleCurrentChange()
          this.matchLogo = ""
          this.logoShow = false
        }
      })
    },
    // 上传渠道LOGO
    handleChange(e) {
      this.matchLogo = e
    },
    handleDelete(e){
      this.matchLogo = ""
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
    // 备注说明
    .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
    }
  .operation-btn{
        /deep/.el-button{
            margin-left: 0;
        }
    }
  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .enable-tip{
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure{
      margin-top: 10px;
      color:#333333;
      font-size:16px;
      text-align: center;
    }
  }
  .command{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .pc-form{
    margin-top: 20px;
    display: flex;
    align-items: center;
    /deep/.el-input{
      flex: 1;
    }
  }
  .flex{
    display: flex;
    align-items: center;
  }
  .flex-je{
    display: flex;
    justify-content: flex-end;
  }
  /deep/.el-img-upload .el-upload{
    background-color: #f5f6fc;
  }
}
</style>
